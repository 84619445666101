<template>
  <div>
    <v-card class="rounded-lg shadow">
      <v-card-title class="align-start d-flex">
        <v-avatar class="elevation-3" color="primary" size="38">
          <v-icon color="white"> mdi-swap-horizontal </v-icon>
        </v-avatar>
        <span class="font-weight-bold text-center ml-3"> Transactions</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <div v-if="loading">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </div>

        <div v-else>
          <div v-if="transactions.data.length > 0">
            <v-data-table
              :headers="headers"
              :items="transactions.data"
              :single-expand="true"
              :expanded.sync="expanded"
              :hide-default-header="true"
              :hide-default-footer="true"
              item-key="id"
              show-expand
              :items-per-page="filter.size"
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{}">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nom</th>
                    <th>Date</th>
                    <th>Montant</th>
                    <th>Destinataire</th>
                    <th>Type</th>
                    <th>Service</th>
                    <th>Mode Paiement</th>
                    <th>Frais</th>
                    <th>Promotion</th>
                    <th>Code Promotion</th>
                    <th>Total</th>
                    <th>Debits</th>

                    <th></th>
                  </tr>
                </thead>
              </template>

              <template v-slot:item="{ item, expand, isExpanded }">
                <tr>
                  <td>{{ item.id }}</td>

                  <td class="text-no-wrap font-weight-medium">
                    <v-avatar size="35">
                      <v-img :src="require('@/assets/avatar.png')" />
                    </v-avatar>
                    {{
                      item.account
                        ? item.account.user
                          ? item.account.user.name
                          : "pas de user"
                        : "pas de compte"
                    }}
                  </td>

                  <td>
                    {{ item.created_at }}
                  </td>

                  <td class="text-no-wrap font-weight-medium gifty--text">
                    {{ CurrencyFormatting(item.amount) }} DZD
                  </td>

                  <td class="text-no-wrap font-weight-medium pointer"
                                                @click="showAccount(item.recipient_account_id)">
                                                <v-avatar size="35" v-if="item.recipient_account">
                                                    <v-img :src="require('@/assets/avatar.png')" />
                                                </v-avatar>
                                                {{ item.recipient_account ? item.recipient_account.user ? item.recipient_account.user.name : "-"
                                                    : "-" }}
                                            </td>

                  <td>
                    <v-btn
                      text
                      color="grey"
                      v-if="item.type === 'sent_refill'"
                      class="text-none"
                    >
                      <v-icon left color="indigo">
                        mdi-arrow-up-right-bold
                      </v-icon>
                      Sode envoyé
                    </v-btn>

                    <v-btn
                      text
                      color="grey"
                      v-if="item.type === 'received_refill'"
                      class="text-none"
                    >
                      <v-icon left color="success">
                        mdi-arrow-down-left-bold
                      </v-icon>
                      Sode reçu
                    </v-btn>

                    <v-btn
                      text
                      color="grey"
                      v-if="item.type === 'voucher'"
                      class="text-none"
                    >
                      <v-icon left color="primary"> mdi-ticket-percent </v-icon>
                      Voucher
                    </v-btn>

                    <v-btn
                      text
                      color="grey"
                      v-if="item.type === 'flexy'"
                      class="text-none"
                    >
                      <v-icon left color="gifty">
                        mdi-cellphone-wireless
                      </v-icon>
                      Flexy
                    </v-btn>

                    <v-btn
                      text
                      color="grey"
                      v-if="item.type === 'Idoom' || item.type === '4G'"
                      class="text-none"
                    >
                      <v-icon left color="info"> mdi-card-multiple </v-icon>
                      Idoom
                    </v-btn>
                  </td>

                  <td>
                    {{ item.service != null ? item.service.name : "-" }}
                  </td>

                  <td class="text-center">
                    <img
                      src="@/assets/carte_CIB.jpg"
                      width="40px"
                      v-if="item.with_e_payment"
                    />
                    <img src="@/assets/logo_gifty.svg" width="40px" v-else />
                  </td>

                  <td
                    class="text-no-wrap font-weight-medium gifty--text text-center"
                  >
                    <span v-if="item.total_transaction_fees > 0">
                      {{ CurrencyFormatting(item.total_transaction_fees) }}
                      DZD
                    </span>
                    <span v-else> / </span>
                  </td>

                  <td
                    class="text-no-wrap font-weight-medium gifty--text text-center"
                  >
                    <span v-if="item.transaction_promotion">
                      {{
                        CurrencyFormatting(
                          item.transaction_promotion.promotion_amount
                        )
                      }}
                      DZD
                    </span>
                    <span v-else> / </span>
                  </td>

                  <td
                    class="text-no-wrap font-weight-medium gifty--text text-center"
                  >
                    <span v-if="item.transaction_promo_code">
                      {{
                        CurrencyFormatting(
                          item.transaction_promo_code.promo_code_amount
                        )
                      }}
                      DZD
                    </span>
                    <span v-else> / </span>
                  </td>
                  <td
                    class="text-no-wrap font-weight-medium gifty--text text-center success lighten-5"
                    style="border-bottom: 1px solid #ddd !important"
                  >
                    {{ CurrencyFormatting(item.total_amount) }} DZD
                  </td>
                  <td>
                    <v-btn
                      icon
                      class="gifty"
                      small
                      @click="openDebits(item)"
                      v-if="item.debits"
                    >
                      <v-icon class="white--text" small>
                        mdi-page-next-outline
                      </v-icon>
                    </v-btn>
                  </td>

                  <td>
                    <v-btn @click.prevent="[expand(!isExpanded)]" icon>
                      <v-icon v-if="!isExpanded"
                        >mdi-plus-circle-outline</v-icon
                      >
                      <v-icon v-if="isExpanded"
                        >mdi-minus-circle-outline</v-icon
                      >
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <DetailsVoucher
                    class="pa-4 grey lighten-5"
                    :item="item"
                    v-if="item.type === 'voucher'"
                    @resendSMS="[resendSms($event)]"
                  />

                  <DetailsCard
                    :item="item"
                    class="pa-4 grey lighten-5"
                    v-if="item.type === 'Idoom' || item.type === '4G'"
                    @resendSMS="[resendSms($event)]"
                  />

                  <DetailsRefill
                    class="pa-4 grey lighten-5"
                    :item="item"
                    v-if="
                      item.type === 'sent_refill' ||
                      item.type === 'received_refill'
                    "
                  />

                  <DetailsFlexy
                    class="pa-4 grey lighten-5"
                    :item="item"
                    v-if="item.type === 'flexy'"
                  />
                </td>
              </template>
            </v-data-table>

            <v-divider />

            <div class="d-flex justify-space-between pl-4 pt-6 pr-4 mb-5">
              <div :style="{ width: '80px' }">
                <v-select
                  outlined
                  v-model="filter.size"
                  dense
                  :items="[5, 10, 20, 30, 40, 50]"
                  @change="onPageChange"
                ></v-select>
              </div>

              <v-spacer />

              <v-pagination
                total-visible="6"
                v-if="transactions.total > 15"
                circle
                v-model="filter.page"
                :length="Math.ceil(transactions.total / filter.size)"
                @input="onPageChange"
              ></v-pagination>
            </div>
          </div>

          <div v-else class="text-center pb-5">
            <v-avatar tile size="200">
              <v-img :src="require('@/assets/database.svg')"></v-img>
            </v-avatar>
            <h3 class="text--primary d-block mb-2">OOPS !</h3>
            <p class="pb-5">Aucun enregistrement correspondant trouvé.</p>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <Debits ref="debitsModal" />
  </div>
</template>

<script>
import DetailsVoucher from "../support/components/DetailsVoucher.vue";
import DetailsCard from "../support/components/DetailsCard.vue";
import DetailsRefill from "../support/components/DetailsRefill.vue";
import DetailsFlexy from "../support/components/DetailsFlexy.vue";
import Debits from "../support/components/Debits.vue";

import { HTTP } from "@/http-common";
export default {
  props: ["account_id"],
  components: {
    DetailsVoucher,
    DetailsCard,
    DetailsRefill,
    DetailsFlexy,
    Debits,
  },
  data() {
    return {
      loading: false,
      transactions: {
        data: [],
      },

      expanded: [],
      singleExpand: true,

      filter: {
        size: 10,
        page: 1,
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Nom", value: "account.user.name" },
        { text: "Date", value: "created_at" },
        { text: "Montant", value: "amount" },
        { text: "Type", value: "type" },
        { text: "Service", value: "service.name" },
        { text: "Mode paiement", value: "service.name" },

        { text: "Frais", value: "service.name" },
        { text: "Promotion", value: "service.name" },
        { text: "Promotion Code", value: "service.name" },
        { text: "Total", value: "service.name" },
        { text: "Actions", value: "" },
      ],
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("v1/users/transactions/" + this.account_id, {
        params: {
          ...this.filter,
        },
      })
        .then((res) => {
          this.transactions = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    onPageChange() {
      this.fetchData();
    },
    openDebits(item) {
      if (item.debits) {
        this.$refs.debitsModal.open(item.debits);
      }
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>