<template>
  <v-container fluid>
    <div class="main">
      <v-skeleton-loader v-if="loading" class="rounded-lg" type="table" />
      <div v-else>
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg mt-2" flat>
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <v-btn class="rounded-lg" color="grey" text @click="$router.go(-1)">
                    <v-icon left>mdi-arrow-left</v-icon>
                    Retour
                  </v-btn>

                  <div v-if="account">
                    <h4 v-if="account.user" class="gifty--text">
                      {{ account.user.name }} ( {{ account.user.phone }} )
                    </h4>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="12">
            <v-card class="rounded-lg mb-5" flat>
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-clock</v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par Type service</span>
              </v-card-title>
            </v-card>
            <statistiques-account  :account="consumeTypes" v-if="!consumeTypesLoading"/>
          </v-col>

          <v-col cols="12">
            <v-card class="rounded-lg mb-5" flat>
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-clock</v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par service</span>
              </v-card-title>
            </v-card>

            <ConsumationService :account_id="$route.params.account_id" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg" flat>
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-clock</v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Montants reçus</span>
              </v-card-title>
              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </v-card-text>
              <v-card-text v-else>
                <div v-if="refills.data && refills.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="font-weight-bold text-center">ID</th>
                          <th class="font-weight-bold text-center">Date</th>
                          <th class="font-weight-bold text-center">Compte</th>
                          <th class="font-weight-bold text-center">Montant</th>
                          <th class="font-weight-bold text-center">Service</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in refills.data" :key="'reffils_' + item.id">
                          <td class="font-weight-bold text-center">
                            {{ item.id }}
                          </td>

                          <td class="font-weight-bold text-center">
                            {{ item.created_at }}
                          </td>

                          <td class="font-weight-bold text-center">
                            {{ item.sender.name }}
                          </td>

                          <td class="font-weight-bold text-center text-no-wrap pink--text darken-2">
                            {{ CurrencyFormatting(item.amount) }} DZD
                          </td>

                          <td class="font-weight-bold text-center">
                            {{ item.service != null ? item.service.name : "-" }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="d-flex mt-4">
                    <span class="mt-2 mr-2">Lignes par page :</span>
                    <v-select v-model="filter.size" :items="[10, 20, 30, 40, 50]" dense filled style="width: 20px"
                      @change="onPageChange"></v-select>
                    <v-spacer />
                    <v-pagination v-if="refills.total > filter.size" v-model="filter.page"
                      :length="Math.ceil(refills.total / filter.size)" circle total-visible="6"
                      @input="onPageChange"></v-pagination>
                  </div>
                </div>
                <div v-else class="text-center">
                  <v-avatar size="200" tile>
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="12">
            <v-skeleton-loader v-if="graphsLoading" type="article" />
            <v-sheet v-else :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-clock</v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par Heure</span>
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <div class="chartAreaWrapper">
                  <Bar :data="graphs.graphHours.values" :is_money="true" :labels="graphs.graphHours.hours" :style="'float: left; width:' +
                    widthGraph(graphs.graphHours.hours) +
                    'px !important;'
                    " title="Consommation par heure" />
                </div>
              </v-card-text>
            </v-sheet>
          </v-col>

          <!-- <v-col lg="12" cols="12">
            <v-skeleton-loader v-if="graphsLoading" type="article" />
            <v-sheet :rounded="'lg'" v-else>
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-clock </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par Jour</span
                >
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <Bar
                  v-if="graphs.graphDays.days.length < 7"
                  :labels="graphs.graphDays.days"
                  :data="graphs.graphDays.values"
                  title="Consommation par Jour (2 derniers mois)"
                  :is_money="true"
                />

                <div class="chartAreaWrapper" v-else>
                  <Bar
                    :labels="graphs.graphDays.days"
                    :data="graphs.graphDays.values"
                    title="Consommation par Jour (2 derniers mois)"
                    :is_money="true"
                    :style="
                      'float: left; width:' +
                      widthGraph(graphs.graphDays.days) +
                      'px !important;'
                    "
                  />
                </div>
              </v-card-text>
            </v-sheet>
          </v-col> -->

          <v-col cols="12" lg="12">
            <v-tabs v-model="tab" active-class="gifty white--text" centered hide-slider>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1"> Mois</v-tab>
              <v-tab href="#tab-2"> Semaines</v-tab>
              <v-tab href="#tab-3"> Jours</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-card flat>
                  <v-card-text>
                    <v-card-title> Consommation par mois (CA)</v-card-title>
                    <v-card-text v-if="graphs.graphMonths && graphs.graphMonths.months">
                      <Bar v-if="graphs.graphMonths.months.length < 7" :data="graphs.graphMonths.values"
                        :labels="graphs.graphMonths.months" is_money="true" title="Consommation par mois (CA)" />

                      <div v-else class="chartAreaWrapper">
                        <Bar :data="graphs.graphMonths.values" :labels="graphs.graphMonths.months" :style="'float: left; width:' +
                          widthGraph(graphs.graphMonths.months) +
                          'px !important;'
                          " is_money="true" title="Consommation par mois (CA)" />
                      </div>
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item value="tab-2">
                <v-card flat>
                  <v-card-text>
                    <v-card-title>
                      Consommation par semaines (CA)
                    </v-card-title>
                    <v-card-text v-if="graphs.graphWeeks && graphs.graphWeeks.weeks">
                      <Bar v-if="graphs.graphWeeks.weeks.length < 7" :data="graphs.graphWeeks.values"
                        :labels="graphs.graphWeeks.weeks" is_money="true" title="Consommation par semaines (CA)" />
                      <div v-else class="chartAreaWrapper">
                        <Bar :data="graphs.graphWeeks.values" :labels="graphs.graphWeeks.weeks" :style="'float: left; width:' +
                          widthGraph(graphs.graphWeeks.weeks) +
                          'px !important;'
                          " is_money="true" title="Consommation par semaines (CA)" />
                      </div>
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item value="tab-3">
                <v-card flat>
                  <v-card-text>
                    <v-card-title>
                      Consommation par Jours 2 derniers mois (CA)
                    </v-card-title>
                    <v-card-text v-if="graphs.graphDays && graphs.graphDays.days">
                      <Bar v-if="graphs.graphDays.days.length < 7" :data="graphs.graphDays.values"
                        :labels="graphs.graphDays.days" is_money="true" title="Consommation par jours (CA)" />
                      <div v-else class="chartAreaWrapper">
                        <Bar :data="graphs.graphDays.values" :labels="graphs.graphDays.days" :style="'float: left; width:' +
                          widthGraph(graphs.graphDays.days) +
                          'px !important;'
                          " is_money="true" title="Consommation par jours (CA)" />
                      </div>
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <Transactions :account_id="$route.params.account_id"></Transactions>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "@/http-common";
import StatistiquesAccount from "@/views/gifty/profile/StatistiquesAccount.vue";
import Transactions from "./Transactions.vue";
import Bar from "@/components/charts/Bar.vue";
//import DoughnutChart from "@/components/charts/DoughnutChart.vue";
import ConsumationService from "./ConsumationServices.vue";

export default {
  components: {
    StatistiquesAccount,
    Transactions,
    Bar,
    //DoughnutChart,
    ConsumationService,
  },
  data() {
    return {
      tab: null,
      account: null,
      loading: false,

      graphsLoading: true,
      data: {},
      graphs: {},
      filter: {
        size: 10,
        page: 1,
      },

      consumeTypes: {},
      consumeTypesLoading: true,
    };
  },
  methods: {
    fetchData() {
      this.loading = true;

      this.fetchGraphs();

      this.$store.dispatch("fetchAnalysesVouchersAccount", {
        id: this.$route.params.account_id,
      });

      this.fetchRefills();

      HTTP.get("/v1/users/statistisiques/" + this.$route.params.account_id)
        .then((res) => {
          this.data = res.data;

          console.log(res.data);
          this.account = res.data.account;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    fetchConsumeByTypes(){
      this.consumeTypesLoading = true;

      HTTP.get("v1/users/statistisiques/consume_by_type/" + this.$route.params.account_id)
        .then((res) => {
          this.consumeTypes = res.data;
          this.consumeTypesLoading = false;
        })
        .catch((err) => {
          this.consumeTypesLoading = false;
          console.log(err);
        });
    },


    fetchGraphs() {
      this.graphsLoading = true;

      HTTP.get("/v1/users/graphs/" + this.$route.params.account_id)
        .then((res) => {
          this.graphs = res.data;
          this.graphsLoading = false;
        })
        .catch((err) => {
          this.graphsLoading = false;
          console.log(err);
        });
    },

    onPageChange() {
      this.fetchRefills();
    },

    fetchRefills() {
      this.$store.dispatch("fetchRefillsAccount", {
        id: this.$route.params.account_id,
        filter: this.filter,
      });
    },
  },
  mounted() {
    this.fetchConsumeByTypes();
    this.fetchData();
    
  },

  computed: {
    refills() {
      return this.$store.getters.getAccountRefills;
    },
    isLoading() {
      return this.$store.getters.isLoadingRefills;
    },
    analyse() {
      return this.$store.getters.getAnalysesVouchersAccount;
    },
  },
};
</script>

<style scoped>
.chartAreaWrapper {
  width: 100%;
  overflow-x: scroll;
}
</style>