<template>
  <div>
    <v-row v-if="loading">
      <v-col cols="6" lg="2" md="3" v-for="index in 8" :key="index">
        <v-skeleton-loader type="article" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
      cols="6" lg="2" md="3"
        v-for="service in services"
        :key="service.id"
      >
        <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
          <v-card-title class="align-center text-center p-4">
            <v-img :src="getImageLink(service.image)" height="125"
                contain style="margin: auto;"></v-img>
          </v-card-title>

          <v-card-text class="align-start">
            <p class="font-weight-bold text-lg mb-1">{{ service.name }}</p>
            <p
              class="
                font-weight-black
                text-lg
                mb-1
                title
                gifty--text
                darken-2
              "
            >
              {{ CurrencyFormatting(service.total) }}
              DZD
            </p>
          </v-card-text>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  props: ["account_id"],
  data() {
    return {
      loading: true,
      services: {},
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("v1/users/getServicesConsumation/" + this.account_id)
        .then((res) => {
          this.services = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>